import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from './userpool';
import { PriceContext } from './context';
import { useContext } from 'react';
import axios from 'axios';

export const authenticate = (Email, Password) => {


    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: Email,
            Pool: userpool
        });

        const authDetails = new AuthenticationDetails({
            Username: Email,
            Password
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {

                // Fetch user attributes after successful login
                user.getUserAttributes((err, attributes) => {
                    if (err) {
                        reject(err);
                    } else {
                        const userData = attributes.reduce((acc, attribute) => {
                            acc[attribute.Name] = attribute.Value;
                            return acc;
                        }, {});

                        axios({
                            method: 'post',
                            url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
                           
                            withCredentials: false,
                            data: {
                              email: userData.email,
                              sub:userData.sub,
                              path:'customer-check'
                            },
                          }).then(function (response) {
                          })
                          .catch(function (error) {
                          });
                    

                            
                                axios({
                                    method: 'post',
                                    url: `https://u8f8tp1qa9.execute-api.us-east-2.amazonaws.com/dev/mypath`,
                                   
                                    withCredentials: false,
                                    data: {
                                      email: userData.email,
                                      path:'free-credit'
                                    },
                                  }).then(function (response) {
                                  })
                                  .catch(function (error) {
                                  });
                            
                            

                           

                            //
                        resolve({ result, userData });
                    }
                });
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });
};


export const signupUser = (email, password) => {
  return new Promise((resolve, reject) => {
      userpool.signUp(email, password, [], null, (err, result) => {
          if (err) {
              reject(err);
              return;
          }
          resolve(result);
      });
  });
};

export const confirmUser = (email, confirmationCode) => {
  return new Promise((resolve, reject) => {
      const user = new CognitoUser({
          Username: email,
          Pool: userpool
      });

      user.confirmRegistration(confirmationCode, true, (err, result) => {
          if (err) {
              reject(err);
              return;
          }
          resolve(result);
      });
  });
};
export const logout = (userHasAuthenticated) => {
    const user = userpool.getCurrentUser();
    if (user) {
        user.signOut();
        userHasAuthenticated(false)
    }
    window.location.href = '/';
};