import { useState, Fragment,createContext, useContext  } from 'react';

const PointsContext = createContext();

const PointsProvider = ({ children }) => {
  const [points, setPoints] = useState([0]);
  const [dates, setDates] = useState([0]);
  const [assists, setAssists] = useState([0]);
  const [rebounds, setRebounds] = useState([])
  const [teams, setTeams] = useState([])
  const [team_name, setTeamNames] = useState([])
  const [playerNames, setPlayerNames] = useState([])
  const [oneName, setName] = useState([])
  const [ PointsPred_l, setPointsPred_l]  = useState(0)
  const [ assistsPred_l, setAssistsPred_l]  = useState(0)
  const [ reboundsPred_l, setReboundsPred_l]  = useState(0)
  const [playerName, setPlayerName] = useState('');

  const [oldPlayer,setOld] = useState('')



  return (
    <PointsContext.Provider value={{ points, setPoints, dates, setDates , assists, setAssists, rebounds, setRebounds, teams, setTeams, team_name, setTeamNames, playerNames, setPlayerNames,oneName, setName,PointsPred_l,setPointsPred_l, setOld, oldPlayer,assistsPred_l, setAssistsPred_l,reboundsPred_l, setReboundsPred_l, playerName, setPlayerName}}>
      {children}
    </PointsContext.Provider>
  );
  }
  export {PointsContext, PointsProvider}