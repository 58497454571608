import React from 'react';
import styled from 'styled-components';



const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width:60%;
  height:33vh;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;

  @media (min-width: 768px) {
    width: 30%;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 24px;
`;

const Price = styled.p`
  font-size: 24px;
  color: #333;
`;

const Description = styled.p`
  color: #777;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;



function PricingCard({setPrice, price, navigate }) {



function handleSubmit(){
    console.log('this is it')
    setPrice(price)
    navigate('/payment')
}
  return (
    <Card>
      <Title>Basic Plan</Title>
      <Price>${price/100}</Price>
      <Description>Some features included in this plan.</Description>
      <Button onClick={handleSubmit}>Subscribe</Button>
    </Card>
  );
}


export default PricingCard;