import { useState, Fragment,createContext, useContext  } from 'react';

const PriceContext = createContext();

const PriceProvider = ({ children }) => {
  const [price, setPrice] = useState(0);
  const [authenticaed, setAuth] = useState(null);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [credit, setCredit] = useState(5);
  const [disabled, setDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [email, setEmailCon] = useState(null);
  const [sub, setSub] = useState(null);




  return (
    <PriceContext.Provider value={{ price, setPrice, isAuthenticated, userHasAuthenticated, credit, setCredit,disabled, setDisable, message, setMessage,email, setEmailCon, sub, setSub}}>
      {children}
    </PriceContext.Provider>
  );
  }
  export {PriceContext, PriceProvider}