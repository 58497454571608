
import axios from 'axios';
import styled from 'styled-components';
import { PointsContext, PointsProvider } from './pointContext';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from "chart.js/auto";
import { useState,useEffect, Fragment, createContext, useContext  } from 'react';
import PlayerForm from "./PlayerForm"
import SportNavBar from './sportNav';
import CreditCounter from './CreditCounter';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const GraphWrapper = styled.div`
  width: 80%;
  height: calc(100% / 3)
  `
const ComponentWrapper = styled.div`
  flex: 1;
  margin: 10px;
`;

const SportApp = () => {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [chartWidth, setChartWidth] = useState('10vw');
  const updateBrowserWidth = () => {
    const newWidth = window.innerWidth;
    setBrowserWidth(newWidth);

    if (newWidth < 500) {
      setChartWidth('100px');
    } else {
      setChartWidth('44vw');
    }
  };
useEffect(() => {
  window.addEventListener('resize', updateBrowserWidth);
  return () => {
      window.removeEventListener('resize', updateBrowserWidth);
  };
}, [chartWidth]);
  const { oldPlayer,setOld,user, points, setPoints, dates, setDates , assists, setAssists, rebounds, setRebounds, teams, setTeams, team_name, setTeamNames, playerNames, setPlayerNames, oneName, setName, setPointsPred_l, PointsPred_l, assistsPred_l, setAssistsPred_l, reboundsPred_l, setReboundsPred_l} = useContext(PointsContext);

  const point_data = {
    labels: dates,
    datasets: [
      {
        label: 'points',
        data: points,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
teams:teams
  };

  const assist_data = {
    labels: dates,
    datasets: [
      {
        label: 'assists',
        data: assists,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  const rebound_data = {
    labels: dates,
    datasets: [
      {
        label: 'rebounds',
        data: rebounds,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  const roptions = {
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          const date = data.labels[tooltipItem.index];
          const team = teams[tooltipItem.index]; // Assuming you have a `teams` variable
          return `Team: ${team}, Date: ${date}, Rebounds: ${value}`;
        },
      },
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false, // Prevents skipping ticks
        }
      }
    }
  };

  const ColumnOne = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnTwo = styled.div`
  flex: 2;
  margin: 10px;
`;
let yourPredictionVariable = 'b44'


  return (
    
    <Fragment>
<Container>
  <ColumnOne>
    <SportNavBar itemss={playerNames} setPlayerNames={setPlayerNames} setTeamNames={setTeamNames} />
    <CreditCounter/>
  </ColumnOne>
  <ColumnTwo>
 
    <ComponentWrapper>
      <PlayerForm setOld={setOld} oldPlayer={oldPlayer} oneName={oneName} setName={setName} setDates={setDates} setPoints={setPoints} points={points} setRebounds={setRebounds} setAssists={setAssists} team={teams} setTeams={setTeams} setPointsPred_l={setPointsPred_l} setAssistsPred_l={setAssistsPred_l} setReboundsPred_l={setReboundsPred_l}/>
    </ComponentWrapper>
    <GraphWrapper>
    <div style={{ height: '10px', width: '100%', backgroundColor: 'yellow', padding: '10px', marginBottom:'20px' }}>{/* Change the color and height as needed */}
        Prediction: {PointsPred_l}
      </div>
      <div style={{ height: '300px', width: {chartWidth}}}>
        <Line data={point_data} options={options} />
      </div>
      <div style={{ height: '10px', width: '100%', backgroundColor: 'yellow',padding: '10px'  }}>{/* Change the color and height as needed */}
        Prediction: {assistsPred_l}
      </div>
      <div style={{ height: '300px', width: {chartWidth} }}>
        <Line data={assist_data} options={options} />
      </div>
      <div style={{ height: '10px', width: '100%', backgroundColor: 'yellow' , padding: '10px' }}>{/* Change the color and height as needed */}
        Prediction: {reboundsPred_l}
      </div>
      <div style={{ height: '300px', width:{chartWidth} }}>
        <Line data={rebound_data} options={roptions} />
      </div>

    </GraphWrapper>
  </ColumnTwo>
</Container>
</Fragment>

    
  );
};

export default SportApp;
