import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PriceProvider } from './context';
import { PointsProvider } from "./sportsc/pointContext";


ReactDOM.render(
  <React.StrictMode>
    <PriceProvider>
    <PointsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </PointsProvider>
    </PriceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);