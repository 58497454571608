import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useContext } from 'react';
import { PriceContext } from './context';
import { Routes, Route , useNavigate, NavLink,Link} from "react-router-dom";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { price, setPrice, setCredit, credit, email, sub} = useContext(PriceContext);

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  function startpayment(price){
    fetch("/payment-start", {
      method: "POST",
      headers: {
      "Content-Type": "application/json",
      },
      body: JSON.stringify({email:email, price, price}),
  }).then(async (result) => {
      var { l} = await result;
      console.log('this is what' ,l)
  });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    startpayment(price)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    const { error , paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: navigate('/analysis'),
      },
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setMessage("Payment successful!");
      // Add logic here to handle the case when the payment goes through
      console.log("Payment succeeded:", paymentIntent);
      if (price==300){
        let holdCredit = 5
        let oldCredit = credit 
        let storeCredit = holdCredit + oldCredit
        setCredit(0)
      
      }
      // Your additional logic here for payment success
    } else {
      setMessage("An unexpected status was returned.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
