import React, { useState } from "react";
import styled from "styled-components";
import { authenticate } from './authenticate';
import userpool from './userpool'
import { useNavigate } from 'react-router-dom';
import { PriceContext } from './context';
import { useContext } from 'react';


const LoginContainer = styled.div`
  max-width: 320px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

function Login() {
  const Navigate = useNavigate();
  const {userHasAuthenticated, setEmailCon} = useContext(PriceContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [loginErr,setLoginErr]=useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    authenticate(email,password)

    .then((data)=>{
      setLoginErr('');
      userHasAuthenticated(true)
      setEmailCon(email)
      // Navigate('/pricing');
    },(err)=>{
      setLoginErr(err.message)
    })
    .catch()
  };

  return (
    <LoginContainer>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormGroup>
        <Button type="submit">Login</Button>
      </form>
    </LoginContainer>
  );
}

export default Login;