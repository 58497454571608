import React from 'react';
import styled from 'styled-components';
import PricingCard from './PricingCard';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: auto; /* Change from 100vh to auto */
  width: 100%; /* Change from 60vw to 100% */
  background-color: #f0f0f0;

  @media (min-width: 7068px) {
    justify-content: space-between;
  }
`;
function Pricing ({setPrice, navigate}) {

  return (
    <CardContainer>
   
      <PricingCard setPrice = {setPrice} price={300} navigate={navigate}/>
      <PricingCard setPrice = {setPrice} price={600} navigate={navigate}/>
      <PricingCard setPrice = {setPrice} price={900} navigate={navigate}/>
    </CardContainer>
  );
};

export default Pricing;