import "./App.css";
import Payment from "./Payment";
import Completion from "./Completion";
import Pricing from "./Pricing";
import  Login  from "./Login";
import Signup from "./Signup"
import SportApp from "./sportsc/SportApp";
import { Routes, Route , useNavigate, NavLink,Link} from "react-router-dom";
import { useContext } from 'react';
import { PriceContext } from './context';
import { AwsConfigAuth } from "./config/auth";
import {Amplify} from "aws-amplify";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { logout } from './authenticate';


function App() {
  const { price, setPrice ,isAuthenticated,userHasAuthenticated} = useContext(PriceContext);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  console.log("this is price", price);
  
  const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;

  .brand {
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
  }

  .links {
    display: flex;
    gap: 1rem;

    a {
      text-decoration: none;
      color: white;
      &:hover {
        color: #ddd;
      }
    }
  }

  .toggle {
    display: none;
  }

  @media (max-width: 768px) {
    .links {
      display: none;
      flex-direction: column;
      width: 100%;
    }

    .toggle {
      display: block;
      cursor: pointer;
    }

    .links.active {
      display: flex;
    }
  }
`;

async function handleLogout(event) {
  event.preventDefault();

  try {
    console.log('logged out')
    logout(userHasAuthenticated)
  } catch (error) {
    console.log(error);
  }
}


  return (
    <main>
      <NavbarContainer>
            <NavLink to="/" className="brand">
              StatTracker
            </NavLink>
            <div className="toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              ☰
            </div>
            <div className={`links ${isMenuOpen ? "active" : ""}`}>
              {isAuthenticated ? (
                <>
                  <Link to="/pricing">pricing</Link>
                  <Link to="/analysis">analysis</Link>

                  <Link to="/" onClick={handleLogout}>
                    Logout
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/signup">Signup</Link>
                  <Link to="/login">Login</Link>
                </>
              )}
            </div>
          </NavbarContainer>

      <Routes>
        <Route path="/payment" element={<Payment />} />
        <Route path="/pricing" element={<Pricing setPrice={setPrice} navigate={navigate}/>} />
        <Route path="/completion" element={<Completion />} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/analysis" element={<SportApp />} />
        <Route path="/login" element={<Login />} />

      </Routes>
    </main>
  );
}

export default App;